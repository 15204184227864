import i18n from '@/plugins/i18n';

interface Options {
  disableDefaultFirstTab: boolean
}

export const usePanels = (languagePrefix?: string, panels?: string[], options?: Partial<Options>) => {
  const panelArray = panels?.map(panel => ({ name: i18n.t(`${languagePrefix}.${panel}`) }) ) ?? [];

  if (!options?.disableDefaultFirstTab) {
    panelArray.unshift({ name: i18n.t('global.defaultTabName') });
  }

  return {
    panels: panelArray,
  };
};
