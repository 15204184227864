<template>
  <div class="display-address">
    {{ address.street }} {{ address.houseNumber }} {{ address.houseNumberAddition }} <br>
    {{ address.zipCode }} {{ address.city }} <br>
    <span v-if="address.countryId">{{ country.find((c) => c.id === address.countryId).name }}</span>
  </div>
</template>

<script>
import country from '@/application/enums/country';

export default {
  name: 'AddressDisplay',
  props: {
    address: {
      required: true,
      type: Object,
    },
  },
  computed: {
    country() {
      return Object.keys(country).map((name) => {
        return {
          name: this.$t(`country.${name}`),
          id: country[name],
        };
      });
    },
  },
};
</script>
