import isEmail from 'validator/lib/isEmail';
import normalizeEmail from 'validator/lib/normalizeEmail';
import isURL from 'validator/lib/isURL';
// https://github.com/validatorjs/validator.js

const default_normalize_url_options = {
  schemes: ['http', 'https'],
  all_lowercase: true,
  outputWithScheme: false,
};

const normalizeUrl = (url, options) => {
  // does not check if it's an actual URL. Use isURL before.
  options = { ...default_normalize_url_options, ...options };
  if (options.all_lowercase) url = url.toLowerCase();

  let split,
    scheme,
    subdomain;

  split = url.split('://');
  if (split.filter(value => value !== '').length > 1) {
    split = split.filter(value => value !== '');
    scheme = split.shift();
  }
  url = split.pop();

  split = url.split(/(.*?)\.(?=[^/]*\..{2,5})/);
  if (split.filter(value => value !== '').length > 1) {
    split = split.filter(value => value !== '');
    subdomain = split.shift();
  }
  const domain = split.pop();

  const fullDomain = [subdomain ? `${subdomain}.` : '', domain].join('');
  if (options.outputWithScheme) {
    return [scheme || 'http', fullDomain].join('://');
  }
  return fullDomain;
};

export default (input) => {
  /*
  isEmail(str [, options])
  ---
  allow_display_name : [@true], the validator will also match Display Name <email-address>.
  require_display_name : [@true], the validator will reject strings without the format Display Name <email-address>.
  allow_utf8_local_part : [@false], the validator will not allow any non-English UTF8 character in email address' local part.
  require_tld : [@false], e-mail addresses without having TLD in their domain will also be matched.
  ignore_max_length : [@true], the validator will not check for the standard max length of an email.
  allow_ip_domain : [@true], the validator will allow IP addresses in the host part.
  domain_specific_validation : [@true], some additional validation will be enabled, e.g. disallowing certain syntactically valid email addresses that are rejected by GMail.
  */
  if (isEmail(input)) {
    /*
    normalizeEmail(email [, options])
    ---
    all_lowercase: true - Transforms the local part (before the @ symbol) of all email addresses to lowercase. Please note that this may violate RFC 5321, which gives providers the possibility to treat the local part of email addresses in a case sensitive way (although in practice most - yet not all - providers don't). The domain part of the email address is always lowercased, as it's case insensitive per RFC 1035.
    gmail_lowercase: true - GMail addresses are known to be case-insensitive, so this switch allows lowercasing them even when all_lowercase is set to false. Please note that when all_lowercase is true, GMail addresses are lowercased regardless of the value of this setting.
    gmail_remove_dots: true: Removes dots from the local part of the email address, as GMail ignores them (e.g. "john.doe" and "johndoe" are considered equal).
    gmail_remove_subaddress: true: Normalizes addresses by removing "sub-addresses", which is the part following a "+" sign (e.g. "foo+bar@gmail.com" becomes "foo@gmail.com").
    gmail_convert_googlemaildotcom: true: Converts addresses with domain @googlemail.com to @gmail.com, as they're equivalent.
    outlookdotcom_lowercase: true - Outlook.com addresses (including Windows Live and Hotmail) are known to be case-insensitive, so this switch allows lowercasing them even when all_lowercase is set to false. Please note that when all_lowercase is true, Outlook.com addresses are lowercased regardless of the value of this setting.
    outlookdotcom_remove_subaddress: true: Normalizes addresses by removing "sub-addresses", which is the part following a "+" sign (e.g. "foo+bar@outlook.com" becomes "foo@outlook.com").
    yahoo_lowercase: true - Yahoo Mail addresses are known to be case-insensitive, so this switch allows lowercasing them even when all_lowercase is set to false. Please note that when all_lowercase is true, Yahoo Mail addresses are lowercased regardless of the value of this setting.
    yahoo_remove_subaddress: true: Normalizes addresses by removing "sub-addresses", which is the part following a "-" sign (e.g. "foo-bar@yahoo.com" becomes "foo@yahoo.com").
    icloud_lowercase: true - iCloud addresses (including MobileMe) are known to be case-insensitive, so this switch allows lowercasing them even when all_lowercase is set to false. Please note that when all_lowercase is true, iCloud addresses are lowercased regardless of the value of this setting.
    icloud_remove_subaddress: true: Normalizes addresses by removing "sub-addresses", which is the part following a "+" sign (e.g. "foo+bar@icloud.com" becomes "foo@icloud.com").
    */
    const normalizedEmail = normalizeEmail(input, {
      gmail_remove_subaddress: false,
      outlookdotcom_remove_subaddress: false,
      yahoo_remove_subaddress: false,
      icloud_remove_subaddress: false,
    });
    return {
      link: `mailto:${normalizedEmail}`,
      text: normalizedEmail,
    };
  }

  /*
  isURL(str [, options])
  ---
  allow_underscores[false]
  require_protocol[false] : if set as true isURL will return false if protocol is not present in the URL.
  require_valid_protocol[true] : isURL will check if the URL's protocol is present in the schemes option.
  schemes[['http', 'https']] : valid schemes can be modified with this option.
  require_host[true] : if set as false isURL will not check if host is present in the URL.
  require_port[false] : if set as true isURL will check if port is present in the URL.
  allow_protocol_relative_urls[false] : if set as true protocol relative URLs will be allowed.
  validate_length[true] : if set as false isURL will skip string length validation (2083 characters is IE max URL length).
  */
  if (isURL(input, {
    allow_underscores: true,
    schemes: ['http', 'https'],
  })) {
    return {
      link: normalizeUrl(input, { outputWithScheme: true }),
      text: normalizeUrl(input),
    };
  }

  return {
    link: input,
    text: input,
  };
};
