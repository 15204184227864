<template>
  <KPaginatedAutocomplete
    :paginator="autocomplete"
    :return-object="returnObject"
    :multiple="multiple"
    v-bind="$attrs"
    item-text="name"
    v-on="$listeners"
  />
</template>

<script>
import KPaginatedAutocomplete from '@/components/crud/fields/KPaginatedAutocomplete.old.vue';
import { autocomplete } from '../api/intermediaryUser.js';
import { mapGetters } from 'vuex';

export default {
  name: 'IntermediaryUserAutocomplete',
  components: { KPaginatedAutocomplete },
  props: {
    fillDefaultUser: {
      type: Boolean,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    returnObject: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters({
      profile: 'authorisation/profile',
    }),
  },
  created() {
    if (!this.fillDefaultUser) this.setDefaultUser();
  },
  methods: {
    autocomplete,

    // Filling the autocomplete with a default user
    setDefaultUser() {
      let selected = {
        id: this.profile.id,
        name: [this.profile.firstName, this.profile.insertion, this.profile.lastName].join(' ').trim(),
      };

      // Default return-object is true, check if boolean is given
      if (!this.returnObject) selected = selected.id;

      // Check if multiple is given
      if (this.multiple) selected = [selected];

      // Emit default value
      this.$emit('input', selected);
    },
  },
};
</script>
