import { get } from '@/application/api/implementations/app';

export interface AddressPayload {
  zipCode: string;
  houseNumber: string;
  houseNumberAddition?: string;
}

export default (payload: AddressPayload) => get('address/find-by-zipcode', {
  params: {
    ...payload,
    houseNumberAddition: payload.houseNumberAddition || undefined,
  },
});
