<template>
  <v-sheet class="k-transparent">
    <VDivider />
    <div class="ma-4">
      <slot />
    </div>
  </v-sheet>
</template>

<script>
export default {
  name: 'KDossier',
};
</script>
