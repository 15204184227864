import Model from '@/application/models/model.js';

class Address extends Model {
  city = '';
  countryId = 1;
  houseNumber = '';
  houseNumberAddition = '';
  street = '';
  zipCode = '';

  mapForRequest() {
    return {
      city: this.city,
      countryId: this.countryId,
      houseNumber: this.houseNumber,
      houseNumberAddition: this.houseNumberAddition,
      street: this.street,
      zipCode: this.zipCode,
    };
  }
}

export default Address;
