
import KAutocomplete from '@/components/crud/fields/KAutocomplete.vue';
import Vue, { PropType, VueConstructor } from 'vue';

export type AutocompleteArguments = [number, number, string, string, boolean, Record<string, undefined>];

interface ComponentData {
  items: Record<string, unknown>[];
  page: number;
  lastPage: number;
  perPage: number;
  search: string; //autocomplete search value
  searchParameter: string; //the value that will be send to the API
  timeout: number;
  isLoading: boolean;
  currentValueItems: Record<string, unknown>[]; // When one or more values are selected and return object is false this will be filled with items
}

/**
 * @TODO at this moment filtering is turned off, when this is activated vuetify filteres based on the item text.
 * But then the result is double filtered and items witch advanced filtering from the API won't appear.
 * When it is enabled (which can't be done) vuetify doesn't show selected items that don't correspond to the search term.
 * When we would remove these from the items, they are no longer visible as a value in the autocomplete itself
 * The only thing we could do is create a custom filter that excludes items that are already selected, but then all
 * selected items are hidden including the ones which do correspond to the filter.
 */
export default (
  Vue as VueConstructor<Vue & {
    $attrs: { multiple: boolean },
    $refs: { autocomplete: Vue }
   }>
).extend({
  name: 'KPaginatedAutocomplete',
  components: { KAutocomplete },
  inheritAttrs: false,
  props: {
    field: {
      type: String,
    },
    paginator: {
      type: Function,
      required: true,
    },
    value: {
      type: [Array, Object, Number, String] as PropType<
        | Record<string, unknown>[]
        | unknown[]
        | Record<string, unknown>
        | string
        | number
      >,
      required: false,
      default: '',
    },
    returnObject: {
      type: Boolean,
      default: true,
    },
    itemText: {
      type: String,
      default: 'name',
    },
    itemValue: {
      type: String,
      default: 'id',
    },
    disabled: {
      type: Boolean,
    },
    blurOnValue: {
      type: Boolean,
      default: false,
    },
  },
  data: (): ComponentData => ({
    items: [],
    page: 1,
    lastPage: 1,
    perPage: 25,
    search: '', //autocomplete search value
    searchParameter: '', //the value that will be send to the API
    timeout: 0,
    isLoading: false,
    currentValueItems: [],
  }),
  computed: {
    /**
     * All attribute that need to be passed to the KAutocomplete.
     * All the values inside this object are overwriteable so only add props here that may be overwritten!
     * @returns Object
     */
    computedAttributes(): unknown {
      return {
        itemText: 'name',
        chips: !!this.$attrs.multiple,
        deletableChips: !!this.$attrs.multiple,
        ...this.$attrs,
      };
    },
    valuesAsArray(): Record<string, unknown>[] | unknown[] {
      if (!this.value) {
        return [];
      }
      return Array.isArray(this.value) ? this.value : [this.value];
    },
    itemsWithCurrentValue(): Record<string, unknown>[] {
      if (this.returnObject) {
        return [
          ...(this.valuesAsArray as Record<string, unknown>[]),
          ...this.items,
        ];
      }
      return [...this.currentValueItems, ...this.items];
    },
  },
  watch: {
    search(newValue, oldValue) {
      if (!oldValue && !newValue) return;
      if (!this.searchIsValid()) return;
      this.handleInitialFetch();
    },
    disabled(value) {
      if (!value) {
        this.handleInitialFetch();
      }
    },
    value: {
      /**
       * When the value changes and we are in a not returnObject situation we need to update the currentValueItems
       * so they'll be remembered in the items list
       */
      async handler(newValue, oldValue) {
        if (newValue && this.blurOnValue) {
          this.blur();
        }
        if (!this.returnObject) {
          if (this.valuesAsArray.length === 0) {
            this.currentValueItems = [];
            return;
          }
          const result = await this.paginator(
            1,
            this.valuesAsArray.length,
            undefined,
            undefined,
            undefined,
            { only: this.valuesAsArray },
          );
          this.currentValueItems = result.data.data;
          return;
        }
        if (!oldValue && !newValue) return;
        this.handleInitialFetch();
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    this.handleInitialFetch();
  },
  methods: {
    async getPaginator(): Promise<[]> {
      this.isLoading = true;
      // currentValues are the id's of selected values in the autocomplete.
      // They will be excluded from the autocomplete, because they are already known
      let currentValues = [];
      if (this.returnObject) {
        currentValues = (this.valuesAsArray as Record<string, unknown>[]).map(
          (item) => item[this.itemValue],
        );
      } else {
        currentValues = this.valuesAsArray as unknown[];
      }
      const result = await this.paginator(
        this.page,
        this.perPage,
        this.searchParameter,
        undefined,
        undefined,
        { exclude: currentValues },
      );
      this.lastPage = result.data.meta.lastPage;
      this.isLoading = false;
      return result.data.data;
    },
    async loadMore(): Promise<void> {
      this.page++;
      const result = await this.getPaginator();
      this.items = [...this.items, ...result];
    },
    async handleInitialFetch(): Promise<void> {
      // timeout for debouncing search requests
      clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        this.searchParameter =
          this.search && this.searchIsValid() ? this.search : '';

        this.page = 1;
        this.items = [];
        if (!this.disabled) {
          this.items = await this.getPaginator();
        }
      }, 400);
    },
    /**
     * Check if the search is valid and not the current selected value
     * this is used because vuetify is sending the selected value also in the search prop
     * in case of a non multiple value
     * @returns {boolean}
     */
    searchIsValid(): boolean {
      if (!this.value) return true;
      /**
       * With the multiple prop, the this.value is not an array. And the described vuetify issue in the method docs
       * is not an issue.
       * this.value could only be Record<string,unknown> in case of this.returnObject or string|number
       * But not an array of those types
       */

      if (this.$attrs.multiple) return true;

      if (this.returnObject) {
        return (
          (this.value as Record<string, unknown>)[this.itemText] !== this.search
        );
      }

      const currentItem = this.items.find(
        (item) => item[this.itemValue] === (this.value as string | number),
      );
      return currentItem ? currentItem[this.itemText] !== this.search : true;
    },
    // Do not delete this method, this may be used by other components via a ref
    refresh(): void {
      this.handleInitialFetch();
    },
    blur(): void {
      this.$refs.autocomplete?.$children[0].blur();
    },
  },
});
