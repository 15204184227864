import { get } from '@/application/api/implementations/app';
import { convertCamelToSnake } from '@/application/api/util/keyConverter.js';
import { AxiosPromise } from 'axios';

export interface PaginatedResponse<T = unknown> {
  data: T;
  meta: {
    currentPage: number;
    from: number;
    lastPage: number;
    perPage: number;
    to: number;
    total: number;
  };
}

export interface PaginatedRequest {
  page?: number;
  perPage?: number;
  search?: string;
  sortBy?: string;
  descending?: boolean;
  params?: Record<string, unknown>;
}

type PaginatedPromise<T> = AxiosPromise<PaginatedResponse<T>>

export const getPaginated = <T = unknown> (
  url: string,
  data: PaginatedRequest,
): PaginatedPromise<T> => {
  return get(url, {
    params: {
      page: data.page || 1,
      perPage: data.perPage || 25,
      ...(data.params || {}),
      search: data.search || undefined,
      sortBy: data.sortBy ? convertCamelToSnake(data.sortBy) : undefined,
      desc:
        typeof data.descending !== 'undefined'
          ? data.descending
            ? '1'
            : '0'
          : undefined,
    },
  });
};
