<template>
  <v-row no-gutters>
    <KTextField
      v-for="field in fieldsSetup"
      :key="field.name"
      v-model="contact[field.name]"
      v-bind="field.settings"
      :field="field.field"
    />
  </v-row>
</template>

<script>
import KTextField from '@/components/crud/fields/KTextField.vue';
import Contact from '@/application/models/Contact';

// Fields prop error validation
const lengthCheck = (fields) => {
  return fields.length === 0 && 'No fields included in the "fields" prop. Either include fields or remove the prop.';
};
const isEveryFieldInModel = (fields) => {
  const checkFields = fields.map((field) => {
    const fieldIsIncludedInModel = Object.keys(new Contact).includes(field.name);
    return !fieldIsIncludedInModel && field.name;
  }).filter(fieldName => fieldName);
  return checkFields.length !== 0 && `Field(s) ${checkFields.join(',')} is/are not included in the contact model. Either add the field(s) to the model or remove the field(s) from the fields prop.`;
};
const fieldsValidator = (fields) => {
  const resolveLengthCheck = lengthCheck(fields);
  const resolveEveryFieldInModel = isEveryFieldInModel(fields);
  if (resolveLengthCheck && process.env.NODE_ENV !== 'production') {
    console.error(resolveLengthCheck);
  }
  if (resolveEveryFieldInModel && process.env.NODE_ENV !== 'production') {
    console.error(resolveEveryFieldInModel);
  }
  return !resolveLengthCheck && !resolveEveryFieldInModel;
};
// END Fields prop error validation

export default {
  name: 'Contact',
  components: {
    KTextField,
  },
  props: {
    value: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    localePrefix: {
      default: 'contactDetail',
    },
    validationFieldPrefix: {
      default: 'contactDetail',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      default: false,
      type: Boolean,
    },
    fields: {
      default: () => [
        {
          name: 'email',
          settings: { required: true },
        },
        {
          name: 'linkedInUrl',
        },
        {
          name: 'phone',
          settings: { required: true },
        },
        {
          name: 'mobilePhone',
        },
        {
          name: 'website',
        }],
      type: Array,
      validator: fieldsValidator,
    },
  },
  data: () => ({
    contact: {},
  }),
  computed: {
    fieldsSetup() {
      return this.fields.map((fieldArgs) => {
        const settings = { ...this.defaultSettings(fieldArgs), ...this.convertToComponentSettings(fieldArgs) };
        return ({
          ...fieldArgs,
          field: settings.field,
          settings,
        });
      });
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.contact = this.value;
      },
    },
    contact() {
      this.$emit('input', this.contact);
    },
  },
  methods: {
    defaultSettings({ name }) {
      const validationField = `${this.validationFieldPrefix}.${name}`;
      const field = `${this.localePrefix}.${name}`;
      const disabled = this.disabled;
      const required = this.disabled ? false : this.required;
      return ({
        validationField,
        disabled,
        required,
        field,
      });
    },
    convertToComponentSettings({ name, settings }) {
      const validationField = settings?.validationFieldPrefix ? `${settings.validationFieldPrefix}.${name}` : undefined;
      const field = settings?.localePrefix ? `${settings.localePrefix}.${name}` : undefined;
      const disabled = settings?.disabled;
      const required = settings?.disabled ? false : settings?.required;
      const settingsArray = Object.entries({
        validationField,
        disabled,
        required,
        field,
      }).filter(([, value]) => value !== undefined);
      return Object.fromEntries(settingsArray);
    },
  },
};
</script>
