
import Field from '@/components/crud/fields/FieldMixin.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'KCheckbox',
  mixins: [Field],
  model: {
    prop: 'inputValue',
    event: 'change',
  },
  props: {
    value: {
      type: Boolean,
    },
    checkboxClass: {
      type: String,
    },
  },
});
