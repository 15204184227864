import { post } from '@/application/api/implementations/app';
import { getPaginated } from '@/application/api/implementations/app/wrapper';

export const create = (scope, scopeId, note) => post('internal-note', {
  scope,
  scopeId,
  note,
});

export const request = (...args) => getPaginated('internal-note', ...args);
