<template>
  <PageLayout>
    <template #header>
      <k-title>{{ department.name }}</k-title>
    </template>
    <template #flow-actions>
      <v-btn
        color="primary"
        depressed
        tile
        @click="openUpdate"
      >
        {{ $t('actions.updateResource', { resource: $tc('department.title') }) }}
      </v-btn>
      <DepartmentForm
        v-model="updateDialog"
        :location="location"
        :request="updateRequest"
        :values="updateFormValues"
        :title="$tc('department.title')"
        is-update-form
        @change="getDepartment"
      />
    </template>
    <template
      v-if="department"
      #default
    >
      <v-row>
        <v-col sm="6">
          <k-dossier>
            <k-display-group language-prefix="department.fields">
              <k-text-display field="costCenter">
                {{ department.costCenter || '-' }}
              </k-text-display>
              <k-text-display field="address">
                <AddressDisplay :address="manualOrLocationAddress.address" />
              </k-text-display>
              <KLinkDisplay
                link-type="phone"
                field="contactDetail.phone"
                :href="department.contactDetail.phone"
              />
              <KLinkDisplay
                link-type="phone"
                field="contactDetail.mobilePhone"
                :href="department.contactDetail.mobilePhone"
              />
              <KLinkDisplay
                link-type="fax"
                field="contactDetail.fax"
                :href="department.contactDetail.fax"
              />
              <KLinkDisplay
                link-type="email"
                field="contactDetail.email"
                :href="department.contactDetail.email"
              />
              <KLinkDisplay
                link-type="website"
                field="contactDetail.website"
                :href="department.contactDetail.website"
              />
              <k-text-display field="iban">
                {{ department.iban }}
              </k-text-display>
              <k-text-display
                field="description"
                class="nl2br"
              >
                {{ department.description }}
              </k-text-display>
            </k-display-group>
          </k-dossier>
        </v-col>
        <v-col sm="6">
          <InternalNote
            v-if="can('department.internalNote')"
            scope="department"
            :scope-id="department.id"
          />
        </v-col>
      </v-row>
    </template>
  </PageLayout>
</template>

<script>
import country from '@/application/enums/country';
import eventBus from '@/application/eventBus.ts';
import Department from '@/application/models/Department.js';
import KDisplayGroup from '@/components/crud/display/KDisplayGroup.vue';
import KLinkDisplay from '@/components/crud/display/KLinkDisplay.vue';
import KTextDisplay from '@/components/crud/display/KTextDisplay.vue';
import PageLayout from '@/components/layout/PageLayout.vue';
import AddressDisplay from '@/modules/address/components/AddressDisplay.vue';
import { show, update } from '@/modules/department/api/department.ts';
import DepartmentForm from '@/modules/department/components/DepartmentForm.vue';
import KTitle from '@/components/layout/KTitle.vue';
import KDossier from '@/components/layout/KDossier.vue';
import InternalNote from '@/modules/internalNote/components/InternalNote.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'Details',
  components: {
    InternalNote,
    KDossier,
    KTitle,
    KLinkDisplay,
    AddressDisplay,
    KDisplayGroup,
    DepartmentForm,
    PageLayout,
    KTextDisplay,
  },
  props: {
    department: {
      type: Object,
    },
    location: {
      type: Object,
    },
    employer: {
      type: Object,
    },
    getDepartment: {
      type: Function,
    },
  },
  data: () => ({
    updateFormValues: new Department(),
    updateDialog: false,
  }),
  computed: {
    ...mapGetters({ can: 'authorisation/can' }),
    country() {
      return Object.keys(country).map((name) => {
        return {
          name: this.$t(`country.${name}`),
          id: country[name],
        };
      });
    },
    manualOrLocationAddress() {
      return this.department.hasManualAddress ? this.department : this.location;
    },
  },
  watch: {
    '$route.params.departmentId': {
      immediate: true,
      handler() {
        if (this.can('department.internalNote')) {
          eventBus.$emit('internalNote', {
            scope: 'department',
            scopeId: this.department.id,
          });
        }
      },
    },
  },
  created() {
    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        to: { name: 'employer.index' },
        text: this.$tc('location.title', 2),
      },
      {
        exact: true,
        to: { name: 'employer.show' },
        text: this.employer.name,
      },
      {
        exact: true,
        to: { name: 'employer.location.index' },
        text: this.$tc('location.title', 2),
      },
      {
        exact: true,
        to: { name: 'employer.location.show' },
        text: this.location.name,
      },
      {
        exact: true,
        to: { name: 'employer.location.department.index' },
        text: this.$tc('department.title', 2),
      },
      {
        exact: true,
        to: { name: 'employer.location.department.show' },
        text: this.department.name,
      },
    ]);
  },
  beforeDestroy() {
    eventBus.$emit('internalNote');
  },
  methods: {
    updateRequest() {
      return update(this.updateFormValues);
    },
    async openUpdate() {
      this.updateFormValues = new Department();
      const response = await show(this.department.id);
      this.updateFormValues.mapResponse(response.data.data);
      this.updateDialog = true;
    },
  },
};
</script>
