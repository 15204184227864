<template>
  <div>
    <slot
      v-if="showOverallLoadingIndicator"
      name="loadingIndicator"
    />
    <slot
      v-else
      :items="paginatedResults"
      :page-number="internalCurrentPage"
    />
    <slot
      name="paginator"
      :is-loading="isLoading"
      :next-page="nextPage"
      :previous-page="previousPage"
      :update-pagination="updatePagination"
      :last-page="lastPage"
      :current-page="internalCurrentPage"
    >
      <v-btn
        v-if="!lastPage || internalCurrentPage < lastPage"
        @click="nextPage"
      >
        Load more
      </v-btn>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'Paginator',
  props: {
    /**
     * @param {number} pageNumber
     * @param {number} itemsPerPage
     * @param {object} [params]
     */
    request: {
      type: Function,
      required: true,
    },
    /**
     * The params that will be send to the 3th parameter of the request function
     */
    params: {
      type: Object,
    },
    /**
     * The number of items per page that are loaded
     */
    itemsPerPage: {
      type: Number,
    },
    /**
     * Determines if it should display a single page at a time,
     * or that the result is concatenated with the previous pages
     */
    singlePage: {
      type: Boolean,
      default: false,
    },
    /**
     * A function that distills the results from the request's response
     */
    resultDistiller: {
      type: Function,
      default: (response) => response,
    },
    lastPage: {
      type: Number,
    },
    /**
     * synced prop currentPage represents the current loaded page, or the page that is being loaded
     */
    currentPage: {
      type: Number,
      default: 1,
    },
  },
  data: () => ({
    isLoading: true,
    isInitialLoading: true,
    paginatedResults: [],
    internalCurrentPage: 1,
  }),
  computed: {
    /**
     * When there is only one page at a time, the global loading indicator should be visible during
     * the switch between pages.
     * When the result of pages is stacked you should only see the global loading indicator the first time
     */
    showOverallLoadingIndicator() {
      return this.singlePage ? this.isLoading : this.isInitialLoading;
    },
  },
  watch: {
    currentPage() {
      this.internalCurrentPage = this.currentPage;
    },
    internalCurrentPage: {
      immediate: true,
      handler() {
        this.handleRequest();
      },
    },
  },
  methods: {
    nextPage() {
      this.updatePagination(this.internalCurrentPage + 1);
    },
    previousPage() {
      this.updatePagination(this.internalCurrentPage - 1);
    },
    reload() {
      if (this.internalCurrentPage !== 1) {
        this.updatePagination(1);
        return;
      }
      this.handleRequest();
    },
    updatePagination(page) {
      this.$emit('currentPage', page);
      this.internalCurrentPage = page;
    },
    async handleRequest() {
      this.isLoading = true;
      const response = await this.request(this.internalCurrentPage, this.itemsPerPage);
      await this.applyResponse(response);
      this.isLoading = false;
      this.isInitialLoading = false;
    },
    async applyResponse(response) {
      if (this.singlePage) {
        this.paginatedResults = this.resultDistiller(response);
        return;
      }
      this.paginatedResults = [...this.paginatedResults, ...this.resultDistiller(response)];
    },
  },
};
</script>
