import type { ContactDetail } from '@/modules/contactDetail/types';

export const formatOneLineContactDetail = (contactDetail?: ContactDetail) => {
  if (!contactDetail) return;

  return [
    contactDetail.email,
    contactDetail.phone,
  ].filter(Boolean).join(', ');
};
