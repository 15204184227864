import Model from '@/application/models/model.js';

interface ContactInterface {
  email: string;
  fax: string;
  linkedInUrl: string;
  phone: string;
  website: string;
  mobilePhone: string;
}

export type ContactForm = ContactInterface;
export type ContactShow = ContactInterface;

class Contact extends Model {
  email = '';
  fax = '';
  linkedInUrl = '';
  phone = '';
  website = '';
  mobilePhone = '';

  mapForRequest(): ContactForm {
    return {
      email: this.email,
      fax: this.fax,
      linkedInUrl: this.linkedInUrl,
      phone: this.phone,
      website: this.website,
      mobilePhone: this.mobilePhone,
    };
  }
}

export default Contact;
