<template>
  <div
    v-if="href"
    class="k-text-display d-flex align-center"
  >
    <div
      v-if="icon"
      class="d-flex mr-4 justify-center align-center iconized flex-shrink-0 flex-grow-0"
    >
      <v-icon :color="iconColor">
        {{ icon }}
      </v-icon>
    </div>
    <div class="overflow-hidden text-truncate">
      <div
        v-if="translatedLabel"
        class="d-flex k-label text--secondary font-italic"
      >
        {{ translatedLabel }}
      </div>
      <a
        :href="transformLink(true)"
        :target="target"
        class="k-value"
      >
        {{ transformLink() }}
      </a>
    </div>
  </div>
</template>

<script>
import linkIt from '@/application/util/linkedIt.js';
import DisplayMixin from '@/components/crud/display/DisplayMixin.vue';

export default {
  name: 'KLinkDisplay',
  mixins: [DisplayMixin],
  props: {
    icon: {
      type: String,
    },
    href: {
      type: String,
    },
    target: {
      type: String,
      default: '_blank',
    },
    linkType: {
      type: String,
    },
    iconColor: {
      type: String,
      default: 'text--primary',
    },
  },
  methods: {
    transformLink(prefix = false) {
      const input = this.href;
      const type = this.linkType;
      if (type === 'fax') return prefix ? `fax:${input}` : input;
      if (type === 'phone') return prefix ? `tel:${input}` : input;

      const { link, text } = linkIt(input);
      if (type === 'email' || type === 'website') return prefix ? link : text;
      return input;
    },
  },
};
</script>

<style scoped>
.k-value {
  min-height: 22px;
}
</style>
