<template>
  <widget :title="$t('candidate.widgets.internalNote.title')">
    <paginator
      ref="paginator"
      :request="getInternalNotes"
      :result-distiller="(response) => response.data.data"
      :items-per-page="5"
      single-page
      :last-page="lastPage"
    >
      <template #default="{ items }">
        <v-row>
          <v-col>
            <v-expansion-panels v-model="open">
              <v-expansion-panel
                v-for="note in items"
                :key="note.id"
              >
                <v-expansion-panel-header>
                  <v-row>
                    <v-col cols="6">
                      {{ note.userName }}
                      <span class="body-2 text--disabled">
                        {{ formatDate(note.createdAt) }}
                      </span>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <span v-html="formatText(note.note)" />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </template>
      <template #loadingIndicator>
        <v-row>
          <v-col>
            <v-expansion-panels
              v-model="open"
              class="expansion-panels-loading"
            >
              <v-expansion-panel
                v-for="note in 5"
                :key="note"
              >
                <v-expansion-panel-header>
                  <v-row>
                    <v-col cols="3">
                      <VSkeletonLoader type="text" />
                    </v-col>
                    <v-col cols="2">
                      <VSkeletonLoader type="text" />
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <VSkeletonLoader type="paragraph@3" />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </template>
      <template #paginator="{ nextPage, previousPage, lastPage, currentPage }">
        <v-row class="mt-0">
          <v-col class="text-start">
            <v-btn
              v-if="currentPage !== 1"
              outlined
              tile
              color="primary"
              @click="closeNotesAndCallback(previousPage)"
            >
              {{ $t('actions.goPrevious') }}
            </v-btn>
          </v-col>
          <v-col class="text-end">
            <v-btn
              v-if="currentPage < lastPage"
              outlined
              tile
              color="primary"
              @click="closeNotesAndCallback(nextPage)"
            >
              {{ $t('actions.goNext') }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </paginator>
  </widget>
</template>

<script>
import Widget from '@/components/widgets/KWidget.vue';
import { request } from '@/modules/internalNote/api/internalNote.js';
import dayjs from '@/plugins/dayjs';
import eventBus from '@/application/eventBus.ts';
import Paginator from '@/components/Paginator.vue';

export default {
  name: 'InternalNote',
  components: {
    Paginator,
    Widget,
  },
  props: {
    scope: {
      type: String,
      required: true,
    },
    scopeId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    internalNotes: [],
    currentPage: 1,
    notesPerPage: 5,
    totalPages: 0,
    isLoading: true,
    open: undefined,
    lastPage: 1,
  }),
  mounted() {
    eventBus.$on('internalNote.created', () => {
      this.$refs.paginator.reload();
    });
  },
  methods: {
    async getInternalNotes(currentPage, itemsPerPage) {
      const response = await request(currentPage, itemsPerPage, null, null, true, {
        scope: this.scope,
        scopeId: this.scopeId,
      });
      this.lastPage = response.data.meta.lastPage;
      return response;
    },
    formatDate(date) {
      return dayjs(date).format('LL');
    },
    formatText(text) {
      return text.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br />$2');
    },
    closeNotesAndCallback(callback) {
      if (this.open === undefined) {
        callback();
        return;
      }

      this.open = undefined;
      setTimeout(() => callback(), 300);
    },
  },
};
</script>

<style scoped lang="scss">
.v-expansion-panel-header .row {
  width: 100%;
}

.expansion-panels-loading .v-skeleton-loader {
  height: 20px;
}
</style>
