import { destroy, get, post, put } from '@/application/api/implementations/app';
import { getPaginated } from '@/application/api/getPaginated';
import { AxiosPromise } from 'axios';
import Department from '@/application/models/Department.js';
import type { PaginatedRequest } from '@/application/api/getPaginated';

export const index = (locationId: number, data: PaginatedRequest): AxiosPromise =>  getPaginated<Record<string, any>[]>(`location/${locationId}/department`, data);

export const autocomplete = (locationId: number, data: PaginatedRequest): AxiosPromise =>  getPaginated<Record<string, any>[]>(`location/${locationId}/department/autocomplete`, data);

export const show = (departmentId: number):AxiosPromise => get(`department/${departmentId}`);

export const create = (locationId: number, department: Department):AxiosPromise => post(`location/${locationId}/department`, department);

export const update = (department: Department):AxiosPromise => put(`department/${department.id}`, department);

export const remove = (departmentId: number):AxiosPromise => destroy(`department/${departmentId}`);

export const groupedDepartments = (employerId: number):AxiosPromise => get(`employer/${employerId}/department`);
