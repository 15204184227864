import { get, post, put } from '@/application/api/implementations/app';
import { AxiosPromise } from 'axios';
import { ContactForm, ContactShow } from '@/application/models/Contact';
import { getPaginated } from '@/application/api/getPaginated';
import type { PaginatedRequest, PaginatedResponse } from '@/application/api/getPaginated';

export const index = (employerId: number, data: PaginatedRequest): AxiosPromise<PaginatedResponse<PersonIndex[]>> => getPaginated(`employer/${employerId}/person`, data);

export const autocomplete = (employerId: number, data: PaginatedRequest): AxiosPromise<PaginatedResponse<PersonAutocomplete[]>> => getPaginated(`employer/${employerId}/person/autocomplete`, data);

export const locationPersonAutocomplete = (locationId: number, data: PaginatedRequest): AxiosPromise<PaginatedResponse<PersonAutocomplete[]>> => getPaginated(`location/${locationId}/person/autocomplete`, data);

export const departmentPersonAutocomplete = (departmentId: number, data: PaginatedRequest): AxiosPromise<PaginatedResponse<PersonAutocomplete[]>> => getPaginated(`department/${departmentId}/person/autocomplete`, data);

export const show = (personId: number) : AxiosPromise<{data: PersonShow}> => get(`person/${personId}`);

export const create = (employerId: number, person: PersonForm) : AxiosPromise<{data: {id: number}}> => post(`employer/${employerId}/person`, person);

export const update = (person: PersonForm): Promise<void> => put(`person/${person.id}`, person);

export interface PersonForm {
  id: number;
  firstName: string;
  insertion: string;
  lastName: string;
  contactDetail: ContactForm;
  locations: number[];
  departments: number[];
  position: string;
  profilePicture: Blob;
}

type AutocompleteObject = {id: number, name: string};

export interface PersonAutocomplete extends AutocompleteObject {
  position: string;
}

export interface PersonShow {
  id: number;
  firstName: string;
  insertion: string;
  lastName: string;
  contactDetail: ContactShow;
  locations: AutocompleteObject[];
  departments: AutocompleteObject[];
  position: string;
 
}

export interface PersonIndex {
  departments: AutocompleteObject[];
  email: string;
  id: number;
  locations: AutocompleteObject[];
  mobilePhone?: string;
  name: string;
  phone: string;
  position?: string;
}
