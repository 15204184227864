<template>
  <v-sheet class="widget mb-8 k-transparent">
    <v-row class="mt-0 pt-2 pb-3 px-6 justify-space-between flex-nowrap">
      <h3
        v-if="title"
        class="text-h3 primary--text text-truncate d-inline-flex"
      >
        {{ title }}
      </h3>
      <!-- not used at the moment, comment in when settings are added -->
      <!-- <v-btn color="grey lighten-1" outlined class="ml-6 d-inline-flex">-->
      <!--   <v-icon color="grey">$settings</v-icon>-->
      <!-- </v-btn>-->
    </v-row>

    <div class="widget-inner text--primary px-3">
      <slot />
    </div>

    <v-row v-if="$slots.actions">
      <slot name="actions" />
    </v-row>
  </v-sheet>
</template>

<script>
export default {
  name: 'Widget',
  props: {
    title: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.widget-inner {
  color: black;
}
</style>
