<template>
  <div
    v-if="slotPassed || value"
    class="d-flex k-text-display"
  >
    <div
      v-if="icon"
      :class="translatedLabel && 'mt-1'"
      class="d-flex mr-3 justify-center align-center iconized"
    >
      <v-icon :color="iconColor">
        {{ icon }}
      </v-icon>
    </div>
    <div>
      <div
        v-if="translatedLabel"
        class="d-flex k-label text--secondary font-italic"
      >
        {{ translatedLabel }}
      </div>
      <div class="d-flex k-value text--primary">
        <slot>
          {{ value }}
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import DisplayMixin from '@/components/crud/display/DisplayMixin.vue';

export default {
  name: 'KTextDisplay',
  mixins: [DisplayMixin],
  props: {
    icon: {
      type: String,
    },
    iconColor: {
      type: String,
      default: 'text--secondary',
    },
    value: {},
  },
  computed: {
    slotPassed() {
      if (!this.$slots.default) return false;
      return !!this.$slots.default[0].context || (this.$slots.default[0].text && !!this.$slots.default[0].text.length);
    },
  },
};
</script>

<style scoped>
.k-value {
  min-height: 22px;
}

.iconized {
  height: 28px;
  width:  28px;
}
</style>
