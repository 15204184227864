import { Address } from '@/modules/address/types';

export const formatOneLineAddress = (address?: Address) => {
  if (!address) return;
  
  const { street, houseNumber, houseNumberAddition, zipCode, city } = address;
  if (![street, houseNumber, zipCode, city].every(Boolean)) return;
  
  return `${street} ${houseNumber}${houseNumberAddition ? houseNumberAddition : ''}, ${zipCode} ${city}`;
};
